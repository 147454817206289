<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table :reload="loadData" v-model:columns="columns">
    <template #search>
      <div :style="{ marginBottom: '30px' }">
        <a-space>
          <a-range-picker v-model:value="quotaTime" valueFormat="YYYY-MM-DD" placeholder="选择时间" />
          <a-button @click="exportData" danger ghost>
            到达时效
          </a-button>
          <a-button @click="transferexportData" type="primary" ghost>
            中转时效
          </a-button>
          <a-button @click="deliveryExportData" danger ghost>
            未交车
          </a-button>
          <a-button @click="imgSumExportData" type="primary" ghost>
            图片数量
          </a-button>
          <a-button @click="inspectionExportData" danger ghost>
            验车信息
          </a-button>
          <a-button @click="receivingExportData" type="primary" ghost>
            接单信息
          </a-button>
          <a-button @click="stockExportData" danger ghost>
            盘库信息
          </a-button>
          <a-button @click="freightExportData" type="primary" ghost>
            运费信息
          </a-button>
          <a-button @click="trackExportData" danger ghost>
            跟踪信息
          </a-button>
          <a-button @click="appletExportData" type="primary" ghost>
            车小象注册
          </a-button>
          <a-button @click="overTimeVehicleExportData" type="primary" ghost>
            超时和严重的车辆
          </a-button>
        </a-space>
      </div>
      <a-form ref="formQueryRef" layout="inline" :model="searchForm">
        <a-form-item label="">
          <a-select v-model:value="searchForm.orgId" class="vehiclenum_orgId" option-filter-prop="children" show-search
            :not-found-content="null" allowClear @select="onSelect" @blur="handleBlur" @change="handleChange"
            placeholder="机构名称" @search="handleSearch">
            <a-select-option v-for="parentOrg in $store.state.app.orgList" :key="parentOrg.id">
              {{ parentOrg.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="" name="quota">
          <a-month-picker v-model:value="searchForm.quota" valueFormat="YYYY-MM" placeholder="指标月份" />
        </a-form-item>
      </a-form>
    </template>
    <template #toolbarRight>
      <a-button type="primary" @click="onSearch">
        <template #icon>
          <SearchOutlined />
        </template>查询
      </a-button>
      <a-button type="primary" ghost @click="add">
        <template #icon>
          <PlusCircleOutlined />
        </template>
        新增
      </a-button>
    </template>
    <a-table :columns="columns" :row-key="record => record.id" :data-source="listData" :loading="loading"
      @change="handleTableChange">
    </a-table>
  </suy-table>
  <a-modal v-model:visible="modalVisible" title="新增指标" :maskClosable="false" :confirm-loading="confirmLoading" @ok="save">
    <a-form ref="formRef" :model="form" layout="vertical" :rules="rules">
      <a-form-item label="货量定额" name="num">
        <a-input v-model:value="form.num" />
      </a-form-item>
      <a-form-item label="机构名称" name="orgId">
        <a-select v-model:value="form.orgId" show-search :filter-option="false" :not-found-content="null"
          @select="onSelect" @change="handleChange" @search="handleSearch">
          <a-select-option v-for="parentOrg in $store.state.app.orgList" :key="parentOrg.id">
            {{ parentOrg.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-col :span="24">
        <a-form-item label="指标时间维度" name="type">
          <a-radio-group v-model:value="form.type">
            <a-radio :value="1">月份</a-radio>
            <a-radio :value="2">季度</a-radio>
            <a-radio :value="3">年份</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-form-item label="指标月份" name="quota">
          <a-month-picker v-model:value="form.quota" class="vehiclenum_quota" valueFormat="YYYY-MM" />
        </a-form-item>
      </a-col>

    </a-form>
  </a-modal>
</template>
<script>

import {
  page, add as adds, quotaexport, transferQuotaexport, deliveryExport, imgSumExport, inspectionExport,
  receivingExport, stockExport, freightExport, trackExport, appletExport, overTimeVehicleExport
} from '@/api/dataCenter/quota'
// import { getOrgList } from '@/api/global'
import { toRefs, reactive, onMounted } from 'vue'
// import { useStore } from 'vuex'
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import SuyTable from '@/components/SuyTable'
export default {

  setup () {
    // const store = useStore()
    const state = reactive({
      formRef: null,
      loading: false,
      pagination: {
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchForm: {
        quota: '2021-07'
      },
      form: {
        quota: '',
        orgId: '',
        num: 0,
        type: 1
      },
      listData: [],
      quotaTime: [],
      columns: [
        {
          title: '排名',
          dataIndex: 'rankNum',
          key: 'rankNum'
        },
        // {
        //   title: '总部/营运区',
        //   dataIndex: 'parentOrgName',
        //   key: 'parentOrgName'
        // },
        {
          title: '上级部门',
          dataIndex: 'superOrgName',
          key: 'superOrgName'
        },
        {
          title: '部门名称',
          dataIndex: 'orgName',
          key: 'orgName'
        },
        // {
        //   title: '责任人',
        //   dataIndex: 'address',
        //   key: 'address'
        // },
        {
          title: '货量指标',
          dataIndex: 'quotaNum',
          key: 'quotaNum'
        },

        {
          title: '当日货量完成',
          dataIndex: 'address',
          key: 'address'
        },
        // {
        //   title: '累计货量完成',
        //   dataIndex: 'completedNum',
        //   key: 'completedNum'
        // },
        // {
        //   title: '月度累计指标',
        //   dataIndex: 'completedNum',
        //   key: 'completedNum'
        // },
        {
          title: '月度累计完成',
          dataIndex: 'completedNum',
          key: 'completedNum'
        },
        {
          title: '月度差值',
          dataIndex: 'notNum',
          key: 'notNum'
        },

        {
          title: '月度完成进度',
          dataIndex: 'completedProgress',
          key: 'completedProgress'
        },
        {
          title: '年度货量指标',
          dataIndex: 'a',
          key: 'a'
        },
        {
          title: '年度累计完成',
          dataIndex: 'a',
          key: 'a'
        },
        {
          title: '年度完成进度',
          dataIndex: 'a',
          key: 'a'
        },
        {
          title: '日期',
          dataIndex: 'quota',
          key: 'quota'
        }

      ],
      modalVisible: false,
      confirmLoading: false,
      rules: {
        quota: [{ required: true, message: '指标月份', trigger: 'change' }],
        orgId: [{ required: true, message: '机构名称', trigger: 'change' }],
        num: [{ required: true, message: '货量定额', trigger: 'change' }]
      },
      orgList: []
    })
    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = async () => {
      state.loading = true
      page({ month: state.searchForm.quota }).then((res) => {
        if (res.code === 10000) {
          state.listData = res.data
        }
        state.loading = false
      })
    }
    onMounted(loadData)
    const handleChange = value => {
    }
    const add = () => {
      state.form = {
        quota: '',
        orgId: '',
        num: 0,
        type: 1
      }
      state.modalVisible = true
    }
    const exportData = () => {
      if (state.quotaTime.length === 0) {
        message.info('请选择时间')
        return
      }
      quotaexport({ startTime: state.quotaTime[0], endTime: state.quotaTime[1] }, '到达时效数据表').then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          // state.modalVisible = false
        }
      }).catch(err => console.log(err))
    }
    const deliveryExportData = () => {
      deliveryExport({}, '在库数据的未交车情况表').then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      }).catch(err => console.log(err))
    }
    const imgSumExportData = () => {
      imgSumExport({ startTime: state.quotaTime[0], endTime: state.quotaTime[1] }, '图片情况表').then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
    const inspectionExportData = () => {
      inspectionExport({ startTime: state.quotaTime[0], endTime: state.quotaTime[1] }, '验车情况表').then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
    const receivingExportData = () => {
      receivingExport({ startTime: state.quotaTime[0], endTime: state.quotaTime[1] }, '接单情况表').then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
    const freightExportData = () => {
      freightExport({ startTime: state.quotaTime[0], endTime: state.quotaTime[1] }, '运费信息').then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
    const trackExportData = () => {
      trackExport({ startTime: state.quotaTime[0], endTime: state.quotaTime[1] }, '跟踪信息').then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
    const transferexportData = () => {
      if (state.quotaTime.length === 0) {
        message.info('请选择时间')
        return
      }
      transferQuotaexport({ startTime: state.quotaTime[0], endTime: state.quotaTime[1] }, '中转时效数据表').then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          // state.modalVisible = false
        }
      }).catch(err => {
        console.log(err)
      })
    }
    const stockExportData = () => {
      stockExport({ startTime: state.quotaTime[0], endTime: state.quotaTime[1] }, '盘库情况表').then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
    const appletExportData = () => {
      appletExport({ startTime: state.quotaTime[0], endTime: state.quotaTime[1] }, '小程序注册').then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
    const overTimeVehicleExportData = () => {
      overTimeVehicleExport({ startTime: state.quotaTime[0], endTime: state.quotaTime[1] }, '超时和严重的车辆').then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
    const onMonth = () => {
      var nowDate = new Date()
      const date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      }
      state.searchForm.quota = '' + date.year + '-' + date.month
      // console.log(date)
    }
    onMounted(onMonth)
    const save = () => {
      state.formRef
        .validate()
        .then(() => {
          state.confirmLoading = true
          if (state.form.type === 3) {
            state.form.quota = state.form.quota.split('-')[0]
          }
          adds({ ...state.form }).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.modalVisible = false
            }
            state.confirmLoading = false
          }).catch(err => {
            state.confirmLoading = false
            console.log(err)
          })
        }).catch(error => {
          console.log(error)
        })
    }

    // getOrgList({}).then(res => { state.orgList = res.data })

    return {
      ...toRefs(state),
      onSearch,
      loadData,
      exportData,
      handleChange,
      deliveryExportData,
      imgSumExportData,
      transferexportData,
      stockExportData,
      inspectionExportData,
      receivingExportData,
      freightExportData,
      trackExportData,
      appletExportData,
      overTimeVehicleExportData,
      add,
      save
    }
  },
  components: {
    PlusCircleOutlined,
    SearchOutlined,
    SuyTable
  }
}
</script>
